import colors from 'vuetify/lib/util/colors';

export const light = {
  primary: (
    process.env.NODE_ENV === 'production'
      ? colors.lightGreen.darken1 // o '#RRGGBB'
      : colors.lightBlue.darken1
  ),
  secondary: (
    process.env.NODE_ENV === 'production'
      ? colors.grey.darken3
      : colors.blueGrey.darken3
  ),
  accent: (
    process.env.NODE_ENV === 'production'
      ? colors.lightGreen.accent2
      : colors.lightBlue.accent2
  ),
};

export const dark = {
  primary: (
    process.env.NODE_ENV === 'production'
      ? colors.lightGreen.darken3 // o '#RRGGBB'
      : colors.lightBlue.darken3
  ),
  secondary: (
    process.env.NODE_ENV === 'production'
      ? colors.grey.darken3
      : colors.blueGrey.darken3
  ),
  accent: (
    process.env.NODE_ENV === 'production'
      ? colors.lightGreen
      : colors.lightBlue
  ),
};
