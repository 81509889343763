import Vue from 'vue';
import Vuex from 'vuex';
import fetch from './fetch.module';
import shared from './shared.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    fetch,
    shared,
  },
});
