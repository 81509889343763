import { isEmpty } from 'lodash';

export const DEFAULT_ALERT_TYPE = 'error';

const INITIAL_STATE = {
  alertMsg: '',
  alertType: DEFAULT_ALERT_TYPE,
  anyLoading: [],
  currentPage: 1,
  firstLoad: true,
  gameInstance: null,
  loading: false,
  lobbyLoading: false, // used to wait for lobby to refresh `casino.json`
  refresh: true,
  selectedBackend: null,
  selectedFrontend: null,
  showFooter: false,
};

const shared = {
  namespaced: true,
  state: { ...INITIAL_STATE },
  actions: {
    cleanup({ commit }) {
      commit('cleanup');
    },
    displayMsg({ commit }, message) {
      commit(
        'displayMessage',
        { message, type: 'success' },
      );
    },
    errorMsg({ commit }, message) {
      commit(
        'displayMessage',
        { message, type: 'error' },
      );
    },
    infoMsg({ commit }, message) {
      commit(
        'displayMessage',
        { message, type: 'info' },
      );
    },
    resetAlertMsg({ commit }) {
      commit('resetAlertMsg');
    },
    selectBackend({ commit }, selectedBackend) {
      commit('selectBackend', selectedBackend);
    },
    selectFrontend({ commit }, selectedFrontend) {
      commit('selectFrontend', selectedFrontend);
    },
    setGameInstance({ commit }, gameInstance) {
      commit('setGameInstance', gameInstance);
    },
    toggleFooter({ commit }, footer) {
      commit('toggleFooter', footer);
    },
    toggleFirstLoad({ commit }, firstLoad) {
      commit('toggleFirstLoad', firstLoad);
    },
    toggleLoading({ commit }, loading) {
      commit('toggleLoading', loading);
    },
    toggleLobby({ commit }, lobbyLoading) {
      commit('toggleLobby', lobbyLoading);
    },
    toggleRefresh({ commit }, refresh) {
      if (!refresh) { // we're switching off
        commit('fetch/clearInterval', false, { root: true });
      }
      commit('toggleRefresh', refresh);
    },
  },
  mutations: {
    cleanup(state) {
      console.log('cleaning up shared store');
      Object.assign(state, INITIAL_STATE);
    },
    displayMessage(state, { message, type }) {
      state.alertMsg = message;
      state.alertType = type;
    },
    resetAlertMsg(state) {
      state.alertMsg = '';
    },
    selectBackend(state, selectedBackend) {
      state.selectedBackend = selectedBackend;
    },
    selectFrontend(state, selectedFrontend) {
      state.selectedFrontend = selectedFrontend;
    },
    setGameInstance(state, gameInstance) {
      state.gameInstance = gameInstance;
    },
    toggleFirstLoad(state, firstLoad) {
      if (firstLoad === undefined) {
        state.firstLoad = !state.firstLoad;
      } else {
        state.firstLoad = firstLoad;
      }
    },
    toggleFooter(state, footer) {
      if (footer === undefined) {
        state.showFooter = !state.showFooter;
      } else {
        state.showFooter = footer;
      }
    },
    toggleLoading(state, loading) {
      if (loading) {
        state.anyLoading.push(true);
      } else {
        state.anyLoading.pop();
      }
      state.loading = !isEmpty(state.anyLoading);
    },
    toggleLobby(state, lobbyLoading) {
      if (lobbyLoading === undefined) {
        state.lobbyLoading = !state.lobbyLoading;
      } else {
        state.lobbyLoading = lobbyLoading;
      }
    },
    toggleRefresh(state, refresh) {
      if (refresh === undefined) {
        state.refresh = !state.refresh;
      } else {
        state.refresh = refresh;
      }
    },
  },
};

export default shared;
