import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { dark, light } from './theme';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      admin: 'mdi-account-key',
      backend: 'mdi-cogs',
      ca: 'mdi-folder-key',
      certify: 'mdi-certificate',
      comment: 'mdi-folder-edit',
      commentSend: 'mdi-comment-plus',
      commentText: 'mdi-comment',
      csha: 'mdi-folder-pound-outline',
      dark: 'mdi-brightness-6',
      deploy: 'mdi-rocket',
      docker: 'mdi-docker',
      download: 'mdi-download',
      gameServer: 'mdi-gamepad-square',
      home: 'mdi-home',
      folder: 'mdi-folder-information',
      frontend: 'mdi-television',
      incompatible: 'mdi-eye-off',
      info: 'mdi-information-variant',
      iris: 'mdi-eye',
      logs: 'mdi-text',
      love: 'mdi-heart-flash',
      logout: 'mdi-logout-variant',
      provider: 'mdi-domain',
      reload: 'mdi-reload',
      remove: 'mdi-delete-empty',
      restart: 'mdi-restart',
      scale: 'mdi-relative-scale',
      search: 'mdi-magnify',
      top: 'mdi-chevron-up',
      update: 'mdi-update',
      upload: 'mdi-upload',
      warning: 'mdi-alert-circle-outline',
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: { light, dark },
  },
});
