/* eslint import/no-extraneous-dependencies: ["error", {"devDependencies": true}] */
/* eslint-disable no-param-reassign */
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import Vue from 'vue';
import Meta from 'vue-meta';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';


function getConfig() {
  // Get the config file (mounted by CM)
  const getUrl = window.location;
  return axios.get(
    `${getUrl.protocol}//${getUrl.host}/web/config.json`
  );
}

getConfig().then((result) => {
  const config = result.data;
  // This is what makes the application include the bearer token in all the
  // outgoing requests
  function tokenInterceptor() {
    axios.interceptors.request.use((axiosConfig) => {
      if (Vue.prototype.$keycloak.authenticated) {
        axiosConfig.headers.Authorization = (
          `Bearer ${Vue.prototype.$keycloak.token}`
        );
      }
      return axiosConfig;
    },
    (error) => Promise.reject(error));
  }
  Vue.config.productionTip = false;
  const maatUrl = (
    config.VUE_APP_MAAT_URL
    || process.env.VUE_APP_MAAT_URL
    || 'https://dev.maatevolution.betserver.es'
  );
  Vue.prototype.$maatUrl = maatUrl;
  Vue.config.devtools = (
    config.VUE_APP_SHOW_DEVTOOLS
    || process.env.NODE_ENV !== 'production'
  );

  Vue.prototype.$maatRole = (
    config.VUE_APP_OIDC_ROLE
    || process.env.VUE_APP_ODIC_ROLE
    || 'maat'
  ); // OIDC role <-- we will look here if we're admin or not

  Vue.use(Meta);
  let app;
  // This is how we inject the keycloak library, which handles the requests for
  // access token and refreshes it every 30s
  const maatPath = process.env.VUE_APP_BASE_URL || '/';
  Vue.use(VueKeycloakJs, {
    logout: {
      redirectUri: `${maatUrl}/${maatPath}/`,
    },
    config: {
      url: (
        config.VUE_APP_KEYCLOAK_URL
        || process.env.VUE_APP_KEYCLOAK_URL
        || 'https://ci-cluster.betserver.es/devops/keycloak/auth'
      ),
      clientId: (
        config.VUE_AP_OIDC_CLIENT_ID
        || process.env.VUE_APP_OIDC_CLIENT_ID || 'maet'
      ),
      realm: (
        config.VUE_APP_OIDC_REALM
        || process.env.VUE_APP_ODIC_REALM || 'rfranco'
      ),
      init: { onLoad: 'login-required' },
    },
    onReady: () => {
      tokenInterceptor();
      /* eslint-disable no-new */
      app = new Vue({
        config,
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    },
  });
}).catch((error) => {
  console.log('Could not get config.json');
  console.log(error);
});


if (window.Cypress) {
  // Add `store` to the window object only when testing with Cypress
  window.app = app;
  window.store = store;
}
