import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/', // is this useless?
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/corelogs/:envName/:name/:version',
      name: 'core-logs',
      component: () => import('@/views/CoreLogs.vue'),
      props: (route) => ({
        ...route.params,
      }),
    },
    {
      path: '/correlation/:envName/:correlationUid',
      name: 'correlation-logs',
      component: () => import('@/views/Correlation.vue'),
      props: (route) => ({
        ...route.params,
      }),
    },
    {
      path: '/environment/:envName',
      name: 'environment',
      props: (route) => ({
        ...route.params,
      }),
      component: () => import('./views/Environment.vue'),
    },
    {
      path: '/environment/:envName/game/:image/',
      name: 'game-edit',
      component: () => import('@/views/GameEdit.vue'),
      props: (route) => ({
        ...route.params,
      }),
    },
    {
      path: '/gamelogs/:envName/:image/:version',
      name: 'game-logs',
      component: () => import('@/views/GameLogs.vue'),
      props: (route) => ({
        ...route.params,
      }),
    },
    {
      path: '/k8s/:envName',
      name: 'k8s',
      props: (route) => ({
        ...route.params,
      }),
      component: () => import('@/views/K8s.vue'),
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('@/views/Logs.vue'),
    },
    {
      path: '/promotions/:envName',
      name: 'promotions',
      component: () => import('@/views/Promotions.vue'),
      props: (route) => ({
        ...route.params,
      }),
    },
    {
      path: '/upload/backend',
      name: 'uploadBE',
      component: () => import('@/views/UploadBackend.vue'),
    },
    {
      path: '/upload/frontend',
      name: 'uploadFE',
      component: () => import('@/views/UploadFrontend.vue'),
    },
    {
      path: '/404',
      name: 'notFound',
      component: () => import('@/views/Fallback.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

export default router;
