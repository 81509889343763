import { get, isEmpty, remove } from 'lodash';
import { labels } from '@/assets/texts.json';
import { fetchData, sendData } from '@/helpers';

export const INITIAL_STATE = {
  backendVersionCount: 0,
  backendVersions: [],
  certificationAuthorities: [],
  coreComponentCount: 0,
  coreComponents: [],
  coreVersion: null,
  environment: null,
  environmentFetchError: false, // shown when an environment is offline
  environmentGroups: [], // list of envGroups: [{name, environments: []}]
  environments: [], // flat list of environments
  frontendVersionCount: 0,
  frontendVersions: [],
  gameInstanceCount: 0,
  gameInstances: [],
  logCount: 0,
  logs: [],
  method: null, // fetch method to auto-refresh
  namespaces: [],
  permissions: {},
  promotions: [],
  providers: [],
  timer: null, // store the fetch timer
  workloads: [],
  workloadDetails: null,
  workloadInfo: null,
};

const loadingOn = function loadingOn(commit) {
  // We need to set root: true for namespaced mutations from other module
  commit('shared/toggleLoading', true, { root: true });
};

const loadingOff = function loadingOff(commit) {
  commit('shared/toggleLoading', false, { root: true });
};

const showAlert = function showAlert(commit, message, type = 'error') {
  commit('shared/displayMessage', { message, type }, { root: true });
};

const fetch = {
  namespaced: true,
  state: { ...INITIAL_STATE },
  actions: {
    clear({ commit }, item) {
      commit('clear', item);
    },
    clearInterval({ commit }) {
      commit('clearInterval');
    },
    applyPromotions({ commit }, { environmentID, body }) {
      loadingOn(commit);
      return sendData({
        url: `/v2/promotions/${environmentID}`,
        body,
        method: 'put',
      }).then((response) => {
        showAlert(
          commit,
          get(response, 'message'),
          'success',
        );
      }).catch((error) => {
        showAlert(
          commit,
          error.response,
          'error',
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    createPromotion({ commit }, { environmentID, body }) {
      loadingOn(commit);
      return sendData({
        url: `/v2/promotions/${environmentID}`,
        body,
        method: 'post',
      }).then((response) => {
        showAlert(
          commit,
          get(response, 'message'),
          'success',
        );
      }).catch((error) => {
        console.log(error);
        showAlert(
          commit,
          labels.unexpectedError,
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    deletePromotions({ commit }, { environmentID, body }) {
      /* eslint prefer-template: "off" */
      loadingOn(commit);
      return sendData({
        url: `/v2/promotions/${environmentID}`,
        body,
        method: 'delete',
      }).then((response) => {
        const deleted = get(response, 'data.deleted');
        if (!isEmpty(deleted)) {
          const msg = (
            'Promotions removed: '
            + deleted.map((v) => `${v.gamename}:${v.version}`)
          );
          showAlert(
            commit,
            msg,
            'success',
          );
          commit('deletePromotions', deleted);
        }
      }).catch((error) => {
        console.log(error);
        showAlert(
          commit,
          labels.unexpectedError,
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchCertificationAuthorities({ commit }) {
      loadingOn(commit);
      return fetchData({
        url: '/v2/certificationauthorities/',
      }).then((response) => {
        commit('setCertificationAuthorities', response.results);
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchCAError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchCoreComponents({ commit }, { environmentID, namespace }) {
      loadingOn(commit);
      return fetchData({
        url: `/agent/workload/${environmentID}`,
        params: { namespace },
      }).then((response) => {
        commit('setCoreComponents', response.deployment);
      }).catch((error) => {
        console.log(error);
        showAlert(
          commit,
          `${labels.fetchEnvironmentError} (id ${environmentID})`,
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchEnvironment({ commit }, environmentID) {
      loadingOn(commit);
      return fetchData({
        url: `/v2/environment/${environmentID}`,
      }).then((response) => {
        commit('setEnvironment', response);
        commit('shared/toggleFirstLoad', false, { root: true });
      }).catch((error) => {
        console.log(error);
        commit('raiseEnvironmentFetchError');
        showAlert(
          commit,
          `${labels.fetchEnvironmentError} (id ${environmentID})`,
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchEnvironmentGroup({ commit }) {
      loadingOn(commit);
      return fetchData({
        url: '/v2/environmentgroup/',
      }).then((response) => {
        commit('setEnvironmentGroups', response.results);
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchEnvGroupError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchEnvironmentPermissions({ commit }, environmentID) {
      loadingOn(commit);
      return fetchData({
        url: `/v2/permissions/environment/${environmentID}`,
      }).then((response) => {
        commit('setPermissions', response);
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchEnvPermissionError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchProviderPermissions({ commit }, providerCode) {
      loadingOn(commit);
      return fetchData({
        url: `/v2/permissions/provider/${providerCode}`,
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchProviderPermissionError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchGameInstances({ commit }, { params, returnValue = false }) {
      // if return, we don't commit the result but return it back
      loadingOn(commit);
      return fetchData({
        url: '/v2/gameinstances',
        params,
      }).then((response) => {
        const gameInstances = get(response, 'results');
        if (returnValue) return gameInstances; /* eslint consistent-return: ["off"] */
        const gameInstanceCount = get(response, 'count');
        commit('setGameInstances', { gameInstances, gameInstanceCount });
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchGameInstancesError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchWorkloadDetails({ commit }, { environmentID, params }) {
      loadingOn(commit);
      return fetchData({
        url: `/agent/workload/info/${environmentID}`,
        params,
      }).then((response) => {
        const containers = get(response, 'spec.template.spec.containers');
        if (!containers.length) return;
        commit('setWorkloadDetails', containers[0].env);
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchGameDetailsError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchWorkloads({ commit }, environmentID) {
      /* eslint no-restricted-syntax: ["off"] */
      loadingOn(commit);
      return fetchData({
        url: `/agent/workload/${environmentID}`,
        params: { 'skip-core-role-filter': true },
      }).then((response) => {
        let workloads = response;
        for (const workload of Object.keys(workloads)) {
          for (const entry of Object.values(workloads[workload])) {
            entry.type = workload;
          }
        }
        workloads = Object.values(workloads).flat();
        commit('setWorkloads', workloads);
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.fetchWorkloadsError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchGameOrCoreLogs({ commit }, { url, params }) {
      loadingOn(commit);
      return fetchData({
        url,
        params,
      }).then((response) => {
        const logs = get(response, 'result');
        return logs;
      }).catch((error) => {
        console.log(error);
        commit(
          'shared/displayMessage',
          { message: labels.logFetchError, type: 'error' },
          { root: true },
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchLogs({ commit }, { logFilter, itemsPerPage, page }) {
      loadingOn(commit);
      const params = {
        ...logFilter && { filter: logFilter },
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
      };
      return fetchData({
        url: '/v2/log',
        params,
      }).then((response) => {
        const logs = get(response, 'results');
        const count = get(response, 'count');
        commit('setLogs', { logs, count });
      }).catch((error) => {
        console.log(error);
        commit(
          'shared/displayMessage',
          { message: labels.logFetchError, type: 'error' },
          { root: true },
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchNamespaces({ commit }, { environmentID, system = false }) {
      loadingOn(commit);
      const params = {...system && { system: true }};
      return fetchData({
        url: `/agent/namespacelist/${environmentID}`,
        params,
      }).then((response) => {
        const namespaces = get(response, 'items', []);
        commit('setNamespaces', namespaces);
      }).catch((error) => {
        console.log(error);
        commit(
          'shared/displayMessage',
          { message: labels.fetchNamespacesError, type: 'error' },
          { root: true },
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchPodInfo({ commit }, { environmentID, params }) {
      loadingOn(commit);
      return fetchData({
        url: `/agent/podinfo/${environmentID}`,
        params,
      }).then((response) => {
        commit('setWorkloadInfo', response);
      }).catch((error) => {
        console.log(error);
        commit(
          'shared/displayMessage',
          { message: labels.fetchNamespacesError, type: 'error' },
          { root: true },
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchPromotions({ commit }, environmentID) {
      loadingOn(commit);
      return fetchData({
        url: `/v2/promotions/${environmentID}`,
      }).then((response) => {
        commit('setPromotions', response);
      }).catch((error) => {
        console.log(error);
        commit(
          'shared/displayMessage',
          { message: labels.fetchPromotionError, type: 'error' },
          { root: true },
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchProviders({ commit }) {
      loadingOn(commit);
      return fetchData({
        url: '/v2/providers',
      }).then((response) => {
        commit('setProviders', get(response, 'results'));
      }).catch((error) => {
        console.log(error);
        commit(
          'shared/displayMessage',
          { message: labels.fetchProviderError, type: 'error' },
          { root: true },
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    fetchVersions({ commit }, { backend, params, full = false }) {
      loadingOn(commit);
      let url;
      if (backend) {
        url = full ? '/v2/games/' : '/v2/games/list';
      } else {
        url = '/v2/frontends';
      }
      return fetchData({
        url,
        params: { ...params },
      }).then((response) => {
        const versions = get(response, 'results');
        const count = get(response, 'count');
        if (backend) {
          commit('setBackendVersions', { versions, count });
        } else {
          commit('setFrontendVersions', { versions, count });
        }
      }).catch((error) => {
        console.log(error);
        showAlert(
          commit,
          labels.fetchGameInstancesError,
          'error',
        );
      }).finally(() => {
        loadingOff(commit);
      });
    },
    manageGame({ commit }, { environmentID, method, data }) {
      loadingOn(commit);
      const url = `/agent/management/${environmentID}`;
      let manage;
      let payload;
      if (['GET', 'DELETE'].includes(method)) {
        manage = fetchData;
        payload = {
          url,
          method,
          params: data,
        };
      } else {
        manage = sendData;
        payload = {
          url,
          method,
          body: data,
        };
      }
      return manage(
        payload,
      ).then((response) => {
        showAlert(
          commit,
          get(response, 'message'),
          'success',
        );
      }).catch((error) => {
        console.log(error);
        const msg = get(
          error,
          'message',
          this.labels.unexpectedError,
        );
        showAlert(commit, msg);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    manageWorkload({ commit }, { environmentID, body, method }) {
      // PUT for restart
      loadingOn(commit);
      const url = `/agent/workload/${environmentID}`;
      return sendData({
        url,
        body,
        method,
      }).then(() => {
        showAlert(
          commit,
          method === 'PUT' ? labels.restartRequested : labels.scaleRequested,
          'success',
        );
      }).catch((error) => {
        console.log(error);
        showAlert(commit, labels.restartError);
      }).finally(() => {
        loadingOff(commit);
      });
    },
    setInterval({ commit }, timeout = 30000) { // 30s
      commit('setInterval', timeout);
    },
    setTimerMethod({ commit }, method) {
      commit('setTimerMethod', method);
    },
  },
  mutations: {
    clear(state, item) {
      state[item] = null;
    },
    clearInterval(state) {
      clearInterval(state.timer);
      state.timer = null;
    },
    deletePromotions(state, deleted) {
      // Remove deleted promotions from our local list
      const promotions = remove(state.promotions, (promotion) => {
        deleted.map((p) => p.id).includes(promotion.id);
      });
      state.promotions = promotions;
    },
    raiseEnvironmentFetchError(state) {
      state.environmentFetchError = true;
    },
    setCoreComponents(state, coreComponents) {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      coreComponents.forEach((deployment) => {
        deployment.started = deployment.last_transition || 0;
        deployment.started = new Date(deployment.started).toLocaleString();
      });
      state.coreComponents = coreComponents;
      state.coreComponentCount = coreComponents.length;
    },
    setEnvironment(state, environment) {
      state.environment = environment;
      state.coreVersion = environment.core_version;
      state.fetchEnvironmentError = false;
    },
    setEnvironmentGroups(state, environmentGroups) {
      state.environmentGroups = environmentGroups;
      state.environments = Object.values(environmentGroups).map(
        (group) => group.environments,
      ).flat();
    },
    setGameInstances(state, { gameInstances, gameInstanceCount }) {
      state.gameInstances = gameInstances;
      state.gameInstanceCount = gameInstanceCount;
    },
    setLogs(state, { logs, count }) {
      state.logs = logs;
      state.logCount = count;
    },
    setBackendVersions(state, { versions, count }) {
      state.backendVersions = versions;
      state.backendVersionCount = count;
    },
    setCertificationAuthorities(state, certificationAuthorities) {
      state.certificationAuthorities = certificationAuthorities;
    },
    setFrontendVersions(state, { versions, count }) {
      state.frontendVersions = versions;
      state.frontendVersionCount = count;
    },
    setWorkloadDetails(state, workloadDetails) {
      state.workloadDetails = workloadDetails;
    },
    async setInterval(state, timeout) {
      // first, clear the timer if existing
      if (state.timer) {
        await clearInterval(state.timer);
      }
      if (state.method) {
        state.timer = setInterval(
          state.method,
          timeout, // fetch every 30s by default
        );
      }
    },
    setNamespaces(state, namespaces) {
      state.namespaces = namespaces;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setPromotions(state, promotions) {
      state.promotions = promotions;
    },
    setProviders(state, providers) {
      state.providers = providers;
    },
    setTimerMethod(state, method) {
      state.method = method;
    },
    setWorkloadInfo(state, workloadInfo) {
      state.workloadInfo = workloadInfo;
    },
    setWorkloads(state, workloads) {
      state.workloads = workloads;
    },
  },
};

export default fetch;
