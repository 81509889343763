/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { compact, get } from 'lodash';
import Vue from 'vue'
import { labels } from '@/assets/texts.json';
import { INITIAL_STATE as FETCH_STATE } from '@/store/fetch.module';

axios.defaults.xsrfHeaderName = 'x-xsrf-token';
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.withCredentials = true;

axios.defaults.baseURL = Vue.prototype.$maatUrl;

axios.defaults.timeout = 60000;

export function getCSRFCookie() {
  return axios({
    url: '/csrf/',
  });
}

export function fetchData({
  url,
  params = {},
  method = 'get',
}) {
  return axios({
    url,
    method,
    params,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.data);
}

export function sendData({
  url,
  body = {},
  method = 'post',
}) {
  return axios({
    url,
    method,
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response);
}

export function formUpload({
  url,
  config = {},
  form = {},
}) {
  return axios.post(
    url,
    form,
    config,
  ).then((response) => response.data);
}

export function getPaginationSize(number, resultCount) {
  return parseInt(
    number === labels.all ? resultCount : number,
    10,
  );
}

export function getName(obj) {
  return get(obj, 'metadata.name');
}

export function getNamespace(obj) {
  return get(obj, 'metadata.namespace');
}

export function checkRouteAndRedirect(router, name, params) {
  // Get current route name and params from router
  const {
    currentRoute: { name: curName, params: curParams },
  } = router;
  if (curName !== name || curParams.envName !== params.envName) {
    router.push({
      name,
      params,
    });
  }
}

export function formatDate(date) {
  return new Date(date).toLocaleString();
}

export function getMany(arr, shown) {
  const tempArr = compact(arr);
  const shownCount = Math.min(shown, tempArr.length);
  const results = (
    tempArr.length > shown
      ? [...tempArr.slice(0, shown), `(+${tempArr.length - shown})`]
      : tempArr.slice(0, shownCount)
  );
  return results.join(', ');
}

export function clearFetch(store, keepCommon = true) {
  clearInterval(store.state.fetch.timer);
  const currentState = {...store.state.fetch};
  const keepable = [
    'environments', 'environmentGroups', 'logs',
    'logCount',
  ]
  let newState = {...store.state};
  Object.keys(store.state.fetch).forEach((key) => {
    if (keepCommon && keepable.includes(key)) {
      newState.fetch[key] = currentState[key];
    } else {
      newState.fetch[key] = FETCH_STATE[key];
    }
  });
  store.replaceState(newState);
}
