<template>
  <v-app v-show="$keycloak.authenticated">

    <app-bar
      @dark="toggleDark"
      @logout="logout"
      @toggle="toggleDrawer"
      :show-dialog-toggle-button="groupsReady"
    />

    <navigation-drawer
      :show-drawer="showDrawer"
    />

    <v-main class="pb-10">
      <!-- key ensures we dont reuse the same values when changing environments back and forth  -->
      <router-view v-if="groupsReady" :key="$route.fullPath" />
    </v-main>

    <popup-message :alertMsg="alertMsg" :alertType="alertType"/>
    <up-button />

    <app-footer v-show="showFooter" />

  </v-app>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';
import { getCSRFCookie } from '@/helpers';

export default {
  name: 'MAÆT',
  components: {
    appBar: () => import('@/components/appBar.vue'),
    appFooter: () => import('@/components/appFooter.vue'),
    navigationDrawer: () => import('@/components/navigationDrawer.vue'),
    popupMessage: () => import('@/components/popupMessage.vue'),
    upButton: () => import('@/components/upButton.vue'),
  },
  created() {
    getCSRFCookie();
    this.fetchEnvironmentGroup();
  },
  computed: {
    ...mapState('fetch', [
      'environmentGroups',
    ]),
    ...mapState('shared', [
      'alertMsg',
      'alertType',
      'showFooter',
    ]),
    dark: {
      get() {
        return this.$vuetify.theme.isDark;
      },
      set(value) {
        this.$vuetify.theme.dark = value;
      },
    },
    groupsReady() {
      return !isEmpty(this.environmentGroups);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data: () => ({
    labels,
    showDrawer: true,
  }),
  methods: {
    ...mapActions({
      cleanup: 'shared/cleanup',
      fetchEnvironmentGroup: 'fetch/fetchEnvironmentGroup',
    }),
    logout() {
      this.cleanup();
      this.$keycloak.logoutFn();
    },
    toggleDark() {
      this.dark = !this.dark;
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
  },
};
</script>

<style lang="scss">
  .v-progress-linear{
    z-index: 9999;
  }
</style>
